import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ogImage from "../images/OG_Image.png";

const Careers = () => (
  <Layout>
    <SEO
      title="Careers"
      ogImage={ogImage}
      keywords={[
        "Flowspring",
        "careers",
        "fintech",
        "asset management",
        "competitive analytics",
      ]}
      description="Consider a career at Flowspring at the confluence of data science, asset management, and competitive intelligence"
    />
    <div className="content">
      {"If you're interested in joining the Flowspring team, please contact us at "}
      <span>
        <a href="mailto:careers@flowspring.com">careers@flowspring.com</a>
      </span>
    </div>
  </Layout>
);

export default Careers;
